import React from "react"
import Layout from "../components/Layout"
import favicon from "../assets/favicon.png"
import defaultImage from "../assets/default-image.jpg"

const Drawing = ({ pageContext }) => {
  return (

    <Layout page={pageContext} type="drawing" originDrawpage={true}>

    </Layout>
  )
}

export default Drawing

export const Head = ({ pageContext }) => (
  <>
      <title>{`${pageContext.lang.slice(0,2) === "fr" ? "dessin" : "drawing"} → the sun project`}</title>
      <meta name="author" content="the sun project" />
      
      <meta property="og:title" content={`${pageContext.lang.slice(0,2) === "fr" ? "dessin" : "drawing"} → the sun project`} />
      <meta property="og:site_name" content="the sun project" />
      <meta property="og:image" content={`https://thesunproject.fr${defaultImage}`} />
      <meta property="og:locale" content={pageContext.lang} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta name="twitter:title"content={`${pageContext.lang.slice(0,2) === "fr" ? "dessin" : "drawing"} → the sun project`} />
      <meta name="twitter:image" content={`https://thesunproject.fr${defaultImage}`} />


      <link rel="icon" type="image/png" href={favicon} />
  </>
)